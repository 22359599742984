import platform from 'platform'

const defaultUserMediaOptions = {
  audio: false,
  video: {
    frameRate: 30,
    width: 640,
    facingMode: 'user'
  }
}

const Camera = {
  video: document.getElementById('video'),
  get width() {
    return this.video.videoWidth
  },
  get height() {
    return this.video.videoHeight
  },
  async start(facingMode = 'user') {
    // we're just paused
    if (this.video && this.stream && this.video.paused) {
      this.video.play()
      return
    }

    // init stream
    const userMediaOptions = defaultUserMediaOptions
    userMediaOptions.video.facingMode = facingMode

    if (navigator.mediaDevices === undefined) {
      throw new Error('NO_CAMERA')
    }

    this.stream = await navigator.mediaDevices.getUserMedia(userMediaOptions)
    const tracks = this.stream.getVideoTracks()
    if (!tracks || tracks.length < 1) {
      throw new Error('NO_CAMERA')
    }

    this.video.srcObject = this.stream
    if (video.paused) {
      await this.video.play()
    }

    try {
      // if we've made it this far, we know we have a front camera
      const capabilities = {
        availableFacingModes: ['user']
      }
      const devices = await navigator.mediaDevices.enumerateDevices()
      devices.forEach(device => {
        if (device.kind === 'videoinput') {
          if (device.label === 'Back Camera') {
            capabilities.availableFacingModes.push('environment')
          }
        }
      })

      return capabilities
    } catch (e) {
      console.warn('Unable to get capabilities')
    }
  },
  async pause() {
    await this.video.pause()
  },
  async stop() {
    await this.pause()
    const tracks = this.stream.getTracks()
    tracks.forEach(async track => await track.stop())
    this.video.srcObject = null
    delete this.stream
  },
  async toggleFacingMode(facingMode = 'user') {
    await this.stop()
    await this.start(facingMode)
  }
}

export default Camera
