import html from 'choo/html'
import IconButton from './icon-button'

const CameraButton = new IconButton('camera-flash', 'Camera')
const GalleryButton = new IconButton('gallery', 'Gallery')

const header = ({ pageTitle, withCamera, withGallery } = {}) => {
  // return html`
  //   <header>
  //     ${withGallery ? GalleryButton.render({ href: '/gallery' }) : null}
  //     <a href="/" title="Emoji Camera homepage">
  //       <h2>Emoji Camera</h2>
  //     </a>
  //     <h3>${pageTitle}</h3>
  //     ${withCamera ? CameraButton.render({ href: '/camera' }) : null}
  //   </header>
  // `

  return html`
    <header>
      ${GalleryButton.render({ href: '/gallery' })}
      <a href="/" title="Emoji Camera homepage">
        <h2>Emoji Camera</h2>
      </a>
      <h3>${pageTitle}</h3>
      ${CameraButton.render({ href: '/camera' })}
    </header>
  `
}

export default header
