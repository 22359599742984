let stripe
let STRIPE_API_KEY

if (process.env.NODE_ENV != 'production') {
  console.log('stripe mode', process.env.STRIPE_MODE)
}

if (process.env.STRIPE_MODE === 'live') {
  stripe = Stripe(process.env.STRIPE_API_KEY_LIVE, {
    betas: ['checkout_beta_4']
  })
} else {
  stripe = Stripe(process.env.STRIPE_API_KEY_TEST, {
    betas: ['checkout_beta_4']
  })
}

export default stripe
