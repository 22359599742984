import html from 'choo/html'
import raw from 'choo/html/raw'
import Slider from '../components/slider'
import EmojiCanvas from '../components/canvas'
import IconButton from '../components/icon-button'
import CircleButton from '../components/circle-button'
import platform from 'platform'

const CaptureButton = new CircleButton()
const GalleryButton = new IconButton('gallery-outline')
const SwitchButton = new IconButton('switch')
const SizeSlider = new Slider({
  name: 'size',
  side: 'left',
  iconEmoji: 'left-mag'
})
const DensitySlider = new Slider({
  name: 'density',
  side: 'right',
  iconEmoji: 'magnet'
})

const CameraEmojiCanvas = new EmojiCanvas()

function onCameraClick(emit) {
  emit('take-snapshot')
}

function onSwitchClick(emit) {
  console.log('on switch click')
  emit('toggle-camera-direction')
}

function statusMessage(state) {
  if (state.doesLackCameraCapability) {
    if (platform.os.family === 'iOS') {
      // prettier-ignore
      return html`
        <div class="status-message">
          Camera not available.<br />
          <p>
            Try loading${raw`&nbsp;`}<a href="https://emoji-camera.com" target="_blank">emoji-camera.com</a>
            from${raw`&nbsp;`}Safari.
          </p>
        </div>
      `
    }
    if (platform.os.family === 'Android') {
      // prettier-ignore
      return html`
        <div class="status-message">
          Camera not available.<br />
          <p>
            Try loading${raw`&nbsp;`}<a href="https://emoji-camera.com" target="_blank">emoji-camera.com</a>
            from${raw`&nbsp;`}Chrome.
          </p>
        </div>
      `
    }
    // prettier-ignore
    return html`
    <div class="status-message">
      Camera not available.<br />
      <p>
        Try${raw`&nbsp;`}<a href="https://emoji-camera.com" target="_blank">emoji-camera.com</a>
        with your phone!
      </p>
    </div>
  `
  }
  if (state.loadingTasksCompleted >= state.loadingTasksTotal) {
    return null
  }
  try {
    const loadingPercent = Math.ceil(
      (state.loadingTasksCompleted / state.loadingTasksTotal) * 100
    )
    return html`
      <div class="status-message">Loading ${loadingPercent}%</div>
    `
  } catch (e) {
    return null
  }
}

function camera(state, emit) {
  const shouldShowSwitchButton =
    state.cameraSettings.availableFacingModes.length > 1

  const renderedSwitchButton = shouldShowSwitchButton
    ? SwitchButton.render({
        classes: 'switch-button',
        onClick: onSwitchClick.bind(null, emit)
      })
    : ''

  // prettier-ignore
  const el = html`
    <main class="camera-page">
        ${ SizeSlider.render(state.controls.size, emit)}
        ${ DensitySlider.render(state.controls.density, emit)}
        ${ GalleryButton.render({ href:"/gallery", classes: "gallery-button"}) }
        ${ CaptureButton.render({ classes: "capture-button", onClick: onCameraClick.bind(this, emit)})}
        ${ renderedSwitchButton }
        ${ CameraEmojiCanvas.render(state.canvasSettings, emit) }
        ${ statusMessage( state )}
        <div class="canvas-background"></div>
    </main>
  `

  return el
}

export default camera
