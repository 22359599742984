import html from 'choo/html'
import platform from 'platform'
import header from '../components/header'
import CopyButton from '../components/copy-button'
import leftPad from 'left-pad'
import getSnapshotIdFromImageId from '../helpers/get-snapshot-id-from-image-id'
import { betaFlag } from '../lib/flags'
const isBetaFlagActive = betaFlag()

const DownloadButton = new CopyButton('download', 'Download')
const MerchButton = new CopyButton('lightning', 'Cool Merch')
// const InstagramButton = new CopyButton('download', 'Instagram')
// ${ InstagramButton.render({ href: 'instagram://library?AssetPath=https%3A%2F%2Fcharlton.local%3A1234%2Femoji.png' })}

const DeleteButton = new CopyButton('nope', 'Delete', 'red')

function onDeleteClick(snapshotId, emit) {
  const shouldDelete = window.confirm(
    'Are you sure you want to delete this picture?'
  )
  if (shouldDelete) {
    emit('delete', snapshotId)
    emit('pushState', '/gallery')
  }
}

function onDownloadClick(e) {
  if (platform.os.family === 'iOS') {
    e.preventDefault()
    alert('Press and hold the image to save it.')
  }
  ga('send', {
    hitType: 'event',
    eventCategory: 'Gallery',
    eventAction: 'download'
  })
}

// const renderedDownloadButton = DownloadButton.render({
//   href: imageObjectURL,
//   onClick: e => {
//     if (platform.os.family === 'iOS') {
//       e.preventDefault()
//       alert('Press and hold the image to save it.')
//     }
//     ga('send', {
//       hitType: 'event',
//       eventCategory: 'Gallery',
//       eventAction: 'download'
//     })
//   },
//   download: 'emoji-camera.png'
// })

function detail(state, emit) {
  const snapshotSlug = state.params.snapshotSlug
  const snapshotId = state.snapshots[snapshotSlug]

  // redirect to gallery if no images
  const userHasNoImages = Object.keys(state.snapshots).length === 0
  if (userHasNoImages) {
    emit('replaceState', '/gallery')
  }

  const isNotValidSlug = typeof snapshotId === 'undefined'
  if (isNotValidSlug) {
    emit('replaceState', '/gallery')
    return html`
      <main></main>
    `
  }

  const pageTitle = `#${leftPad(snapshotSlug, 3, '0')}`

  const imageBlob = state.snapshotBlobs[snapshotId]
  if (!imageBlob) {
    // prettier-ignore
    return html`
      <main class="detail-page">
        ${ header({ pageTitle: pageTitle, withCamera: true, withGallery: true}) }
      </main>
    `
  }

  const imageObjectURL = URL.createObjectURL(imageBlob)
  const img = html`
    <img class="detail-image" src="${imageObjectURL}" />
  `
  img.onload = () => img.classList.add('is-loaded')
  if (img.complete) img.classList.add('is-loaded')

  // prettier-ignore
  console.log(snapshotSlug)
  const downloadName = `Emoji Camera ${leftPad(snapshotSlug, 3, '0')}.png`
  return html`
    <main class="detail-page">
      ${header({ pageTitle: pageTitle, withCamera: true, withGallery: true })}
      ${img} ${MerchButton.render({ href: `/gallery/${snapshotSlug}/merch` })}
      ${DownloadButton.render({
        href: imageObjectURL,
        onClick: onDownloadClick,
        download: downloadName
      })}
      <div class="line"></div>
      ${DeleteButton.render({ onClick: () => onDeleteClick(snapshotId, emit) })}
    </main>
  `
}

export default detail
