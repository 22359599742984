import html from 'choo/html'
import header from '../components/header'
import getProductFromSlug from '../helpers/get-product-from-slug'
import CopyButton from '../components/copy-button'

const PurchaseButton = new CopyButton('lightning', 'Enter Payment')

const onPurchaseClick = (emit, product, snapshotId) => {
  document.getElementById('submit-button').click()
  // if (product.skus.length === 1) {
  //   console.log(document.getElementById('shipping-form')){

  //   }
  //   // const skuId = product.skus[0].id

  //   // emit('product-purchase', {
  //   //   snapshotId,
  //   //   productId: product.id,
  //   //   skuId
  //   // })
  // } else {
  //   throw new Error('multi sku products not yet supported')
  // }
}

const onSubmit = (emit, product, snapshotId, e) => {
  e.preventDefault()
  const form = e.target

  const quantity = parseInt(form.quantity.value)
  const name = form.name.value
  const address1 = form.address1.value
  const address2 = form.address2.value
  const city = form.city.value
  const state = form.state.value
  const zip = form.zip.value
  const country = form.country.value

  const purchaseInfo = {
    details: {
      quantity
    },
    shipping: {
      name,
      address1,
      address2,
      city,
      state,
      zip,
      country
    }
  }
  emit('product-purchase', {
    snapshotId,
    productId: product.id,
    skuId: product.activeSku,
    ...purchaseInfo
  })
}

function merchDetail(state, emit) {
  const snapshotSlug = state.params.snapshotSlug
  const snapshotId = state.snapshots[snapshotSlug]

  if (state.products === null) {
    emit('get-products')
  }

  // redirect to gallery if no images
  const userHasNoImages = Object.keys(state.snapshots).length === 0
  if (userHasNoImages) {
    emit('replaceState', '/gallery')
  }

  const isNotValidSlug = typeof snapshotId === 'undefined'
  if (isNotValidSlug) {
    emit('replaceState', '/gallery')
  }

  const product = getProductFromSlug(state.products, state.params.merchItemSlug)
  const productId = product ? product.id : null

  if (product === null) {
    return html`
      <main></main>
    `
  }

  const canHaveMockup = product.metadata.printful_product_id

  // if no mockups exist
  let mockups = null
  if (state.mockups[snapshotId] === undefined && canHaveMockup) {
    emit('request-mockup', {
      snapshotId,
      productId
    })
  } else if (typeof state.mockups[snapshotId] === 'object') {
    mockups = state.mockups[snapshotId][product.id]
  }

  const boundOnPurchaseClick = onPurchaseClick.bind(
    null,
    emit,
    product,
    snapshotId
  )

  const boundOnSubmit = onSubmit.bind(null, emit, product, snapshotId)

  const pageTitle = product.metadata.store_name

  let generatingPreviewMessage = canHaveMockup
    ? html`
        <p>Generating your preview...</p>
      `
    : null

  let merchImage = html`
    <img class="item-image" src="${product.metadata.store_image}" />
  `

  if (mockups !== null && typeof mockups === 'object') {
    window.mockups = mockups
    const firstMockup = mockups[Object.keys(mockups)[0]][0]
    merchImage = html`
      <img class="item-image" src="${firstMockup}" />
    `
    generatingPreviewMessage = null
  }

  let itemPrice
  if (product.minPrice === product.maxPrice) {
    itemPrice = `${product.minPrice / 100} USD`
  } else {
    itemPrice = `${product.minPrice / 100}+ USD`
  }

  // prettier-ignore
  return html`
    <main class="merch-detail-page merch-shipping-page">
    ${ header({ pageTitle: pageTitle, withCamera: true, withGallery: true}) }
      <div class="merch-item">
        ${ merchImage }
        ${ generatingPreviewMessage }
        
        <form id="shipping-form" method="get" action="/" onsubmit=${boundOnSubmit}>
        <p class="form-section-heading">Details</p>
          <p class="item-description">How many of these bad boys do you want?</p>
          <label for="quantity">Quantity</label>
          <select id="quantity" required>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
          </select>
          <p class="form-section-heading">Shipping</p>
          <p class="item-description">Where do we send this ${product.metadata.store_name}?</p>
          <label for="name">Recipient's Name</label>
          <input type="text" id="name" name="name" required />
          <label for="address1">Address</label>
          <input type="text" id="address1" name="address1" required />
          <label for="address2">Apt, Suite</label>
          <input type="text" id="address2" name="address2" />
          <label for="city" required>City</label>
          <input type="text" id="city" name="city" />
          <label for="state">State</label>
          <select id="state" required>
            <option value="">--Select--</option>
            <option value="AA">Armed Forces Americas (except Canada)</option>
            <option value="AE">Armed Forces</option>
            <option value="AK">Alaska</option>
            <option value="AL">Alabama</option>
            <option value="AP">Armed Forces Pacific</option>
            <option value="AR">Arkansas</option>
            <option value="AS">American Samoa</option>
            <option value="AZ">Arizona</option>
            <option value="CA">California</option>
            <option value="CO">Colorado</option>
            <option value="CT">Connecticut</option>
            <option value="DC">District of Columbia</option>
            <option value="DE">Delaware</option>
            <option value="FL">Florida</option>
            <option value="FM">Federated States of Micronesia</option>
            <option value="GA">Georgia</option>
            <option value="GU">Guam</option>
            <option value="HI">Hawaii</option>
            <option value="IA">Iowa</option>
            <option value="ID">Idaho</option>
            <option value="IL">Illinois</option>
            <option value="IN">Indiana</option>
            <option value="KS">Kansas</option>
            <option value="KY">Kentucky</option>
            <option value="LA">Louisiana</option>
            <option value="MA">Massachusetts</option>
            <option value="MD">Maryland</option>
            <option value="ME">Maine</option>
            <option value="MH">Marshall Islands</option>
            <option value="MI">Michigan</option>
            <option value="MN">Minnesota</option>
            <option value="MO">Missouri</option>
            <option value="MP">Northern Mariana Islands</option>
            <option value="MS">Mississippi</option>
            <option value="MT">Montana</option>
            <option value="NC">North Carolina</option>
            <option value="ND">North Dakota</option>
            <option value="NE">Nebraska</option>
            <option value="NH">New Hampshire</option>
            <option value="NJ">New Jersey</option>
            <option value="NM">New Mexico</option>
            <option value="NV">Nevada</option>
            <option value="NY">New York</option>
            <option value="OH">Ohio</option>
            <option value="OK">Oklahoma</option>
            <option value="OR">Oregon</option>
            <option value="PA">Pennsylvania</option>
            <option value="PR">Puerto Rico</option>
            <option value="PW">Palau</option>
            <option value="RI">Rhode Island</option>
            <option value="SC">South Carolina</option>
            <option value="SD">South Dakota</option>
            <option value="TN">Tennessee</option>
            <option value="TX">Texas</option>
            <option value="UT">Utah</option>
            <option value="VA">Virginia</option>
            <option value="VI">Virgin Islands</option>
            <option value="VT">Vermont</option>
            <option value="WA">Washington</option>
            <option value="WI">Wisconsin</option>
            <option value="WV">West Virginia</option>
            <option value="WY">Wyoming</option>
          </select>
          <label for="zip" required>Zipcode</label>
          <input type="tel" id="zip" name="zip" required />
          <label for="country">Country</label>
          <select id="country" name="country" disabled required >
            <option value="US">US Only, More Coming!</option>
          </select>
          <input id="submit-button" type="submit" value="Enter Payment"/>
          </form>
          <p class="item-description">We literally couldn't be happier right now.</p>
          ${ state.isAwaitingPurchase ? html`<p>Loading...</p>` : PurchaseButton.render({ onClick: boundOnPurchaseClick  }) }
      </div>
    </main>
  `
}

export default merchDetail
