import Button from './button'
import html from 'choo/html'

class CopyButton extends Button {
  constructor(iconEmoji, copy, copyColor) {
    super()
    this.iconEmoji = iconEmoji
    this.copy = copy
    this.copyColor = copyColor
  }
  createElement(props) {
    props.copy = html`
      <img
        class="emoji ${this.iconEmoji}"
        src="/icons/${this.iconEmoji}.svg"
        alt="${this.iconEmoji}"
      />
      ${this.copy}
      <img
        class="emoji ${this.iconEmoji}"
        src="/icons/${this.iconEmoji}.svg"
        alt="${this.iconEmoji}"
      />
    `
    // props.copy = this.copy
    const el = super.createElement(props)
    el.classList.add('copy-button')
    return el
  }
}

export default CopyButton
