import html from 'choo/html'
import header from '../components/header'
import MerchItemGrid from '../components/merch-item-grid'
import leftPad from 'left-pad'

const merchItemGrid = new MerchItemGrid()

function merch(state, emit) {
  const snapshotSlug = state.params.snapshotSlug
  const snapshotId = state.snapshots[snapshotSlug]

  if (state.products === null) {
    emit('get-products')
  }

  // redirect to gallery if no images
  if (Object.keys(state.snapshots).length === 0) {
    emit('replaceState', '/gallery')
  }

  const pageTitle = `#${leftPad(snapshotSlug, 3, '0')} Merch`

  // prettier-ignore
  return html`
    <main class="merch-page">
      ${ header({ pageTitle: pageTitle, withCamera: true, withGallery: true}) }
      ${ merchItemGrid.render({ snapshotSlug, products: state.products })}
    </main>
  `
}

export default merch
