import 'babel-polyfill'
import choo from 'choo'

import Homepage from './pages/Homepage'
import CameraPage from './pages/CameraPage'
import GalleryPage from './pages/GalleryPage'
import DetailPage from './pages/DetailPage'
import MerchPage from './pages/MerchPage'
import MerchDetailPage from './pages/MerchDetailPage'
import ShippingInfoPage from './pages/ShippingInfoPage'
import SuccessPage from './pages/SuccessPage'

import store from './store'

const app = choo()
if (process.env.NODE_ENV !== 'production') {
  app.use(require('choo-devtools')())
}
app.use(store)
app.route('/', Homepage)
app.route('/camera', CameraPage)
app.route('/gallery', GalleryPage)
app.route('/gallery/:snapshotSlug', DetailPage)
app.route('/gallery/:snapshotSlug/merch', MerchPage)
app.route('/gallery/:snapshotSlug/merch/:merchItemSlug', MerchDetailPage)
app.route(
  '/gallery/:snapshotSlug/merch/:merchItemSlug/shipping',
  ShippingInfoPage
)
app.route('/gallery/:snapshotSlug/merch/:merchItemSlug/success', SuccessPage)
app.mount('main')

// worker
const initWorker = () => {
  const worker = new Worker('worker.js')
  app.emitter.emit('worker-initted', worker)
}

const onLoad = () => {
  initWorker()
}
if (document.readyState === 'complete') {
  setTimeout(onLoad, 1)
} else {
  window.addEventListener('load', onLoad)
}

// iOS 100vh workaround
const onResize = () => {
  const doc = document.documentElement
  doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  app.emitter.emit('resize')
}
window.addEventListener('resize', onResize)
onResize()

const onError = (message, source, lineno, colno, error) => {
  ga('send', 'exception', {
    exDescription: message
  })
}
window.onerror = onError
