import html from 'choo/html'
import Component from 'choo/Component'

class Button extends Component {
  constructor() {
    super()
    this.isTouching = false
    this.onTouchStart = this.onTouchStart.bind(this)
    this.onTouchEnd = this.onTouchEnd.bind(this)
    this.onTouchMove = this.onTouchMove.bind(this)
    this.onClick = this.onClick.bind(this)
  }
  onTouchStart(e) {
    this.isTouching = true
    this.rerender()
  }
  onTouchEnd() {
    this.isTouching = false
    this.rerender()
  }
  onTouchMove() {}
  onClick(e) {
    if (this.onClickProp) {
      e.preventDefault()
      this.onClickProp.call(this, e)
    }
  }

  update() {
    return true
  }

  createElement(props) {
    const {
      href = null,
      onClick,
      copy = '',
      classes = '',
      download = null
    } = props

    this.tagName = href ? 'a' : 'div'

    const el = html`
    <${this.tagName} ${this.attributes}
        class="button ${this.isTouching ? 'has-active-touch' : ''} ${classes}"
        ontouchstart=${props.onTouchStart || this.onTouchStart}
        ontouchend=${props.onTouchEnd || this.onTouchEnd}
        ontouchcancel=${props.onTouchEnd || this.onTouchEnd}
        ontouchmove=${props.onTouchMove || this.onTouchMove}
        onmousedown=${props.onTouchStart || this.onTouchStart}
        onmouseup=${props.onTouchEnd || this.onTouchEnd}
        onclick=${props.onClick || this.onClick}
        ><div>${copy}</div></${this.tagName}>
    `

    if (href) {
      el.setAttribute('href', href)
    }

    if (download) {
      el.setAttribute('download', download)
    }

    if (this.copyColor) {
      el.classList.add(this.copyColor)
    }

    return el
  }
}

export default Button
