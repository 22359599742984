import html from 'choo/html'
import Component from 'choo/Component'

class Canvas extends Component {
  constructor() {
    super()

    // outside the choo render loop
    this.sizeInput = document.getElementById('size-setting')
  }
  async load(element) {
    this.emit('start-camera')
  }
  async unload() {
    this.emit('stop-camera')
  }

  update(state, emit) {
    return true
    // let shouldUpdate = false
    // if (state.size.value != this.lastState.size.value) shouldUpdate = true
    // if (state.density.value != this.lastState.density.value) shouldUpdate = true
    // console.log('should update', shouldUpdate)
    // return shouldUpdate
  }

  createElement(state, emit) {
    this.emit = emit

    // this.lastState = state

    // const minFontSize = 10
    // const maxFontSize = 100
    // const fontSize =
    //   (maxFontSize - minFontSize) * state.size.value + minFontSize

    // this.sizeInput.value = fontSize

    return html`
      <div class="canvas-dummy"></div>
    `
  }
}

export default Canvas
