import Button from './button'
import html from 'choo/html'

class IconButton extends Button {
  constructor(iconEmoji) {
    super()
    this.iconEmoji = iconEmoji
  }
  createElement(props = {}) {
    props.copy = html`
      <img
        draggable="false"
        src="/icons/${this.iconEmoji}.svg"
        alt="${this.iconEmoji}"
      />
    `

    const el = super.createElement(props)
    el.classList.add('icon-button')
    el.classList.add(`${this.iconEmoji}-button`)

    return el
  }
}

export default IconButton
