import html from 'choo/html'
import header from '../components/header'
import CopyButton from '../components/copy-button'

const DownloadButton = new CopyButton('download', 'Download SVG')
const CameraButton = new CopyButton('camera-flash', 'Take More')
const GalleryButton = new CopyButton('gallery', 'Gallery')

let hasPurchaseBeenRecorded

function success(state, emit) {
  const snapshotSlug = state.params.snapshotSlug
  const snapshotId = state.snapshots[snapshotSlug]

  const currentUrl = new URL(window.location.href)
  const purchaseId = currentUrl.searchParams.get('purchaseId')
  const productId = currentUrl.searchParams.get('productId')
  const skuId = currentUrl.searchParams.get('skuId')

  if (!purchaseId || !productId || !skuId) {
    emit(
      'replaceState',
      `/gallery/${snapshotSlug}/merch/${state.params.merchItemSlug}`
    )
  }

  if (!hasPurchaseBeenRecorded) {
    hasPurchaseBeenRecorded = localStorage.getItem(`ecp_${purchaseId}`)
  }

  if (!hasPurchaseBeenRecorded) {
    localStorage.setItem(`ecp_${purchaseId}`, 1)
    hasPurchaseBeenRecorded = true

    ga('send', {
      hitType: 'event',
      eventCategory: 'Merch',
      eventAction: 'purchase-success',
      eventLabel: productId,
      eventValue: 5
    })
  }

  if (!state.purchaseResult) {
    emit('get-purchase', {
      purchaseId,
      productId,
      skuId
    })
  }

  if (state.products === null) {
    emit('get-products')
  }

  if (state.purchaseResult === 'pending') {
    return html`
      <main></main>
    `
  }

  console.log(state.purchaseResult)

  const downloadSlug = state.purchaseResult.downloadSlug
  const downloadUrl = `https://${window.location.host}/download/${downloadSlug}`

  // redirect to gallery if no images
  const userHasNoImages = Object.keys(state.snapshots).length === 0
  if (userHasNoImages) {
    emit('pushState', '/gallery')
  }

  const isNotValidSlug = typeof snapshotId === 'undefined'
  if (isNotValidSlug) {
    emit('pushState', '/gallery')
    return html`
      <main></main>
    `
  }

  const pageTitle = `You got it!`

  // const sku = getSkuFromSlug(state.params.merchItemSlug)

  // const checkAgain = () => emit('request-download-slug', snapshotId)

  // prettier-ignore
  const renderedProcessingMessage = html`
    <span>
      <p class="item-description">
        We're cooking up your new hotness. This only takes a minute. 
        We'll send you an email when we're done or just hold tight. It'll show up right here.
      </p>
    </span>
  `

  const renderedDownloadButton = downloadUrl
    ? DownloadButton.render({ href: downloadUrl })
    : null
  const renderedDownloadMessage = html`
    <span>
      <p class="item-description">
        We've emailed you a receipt (from Yonder Studio) and a permanent
        download link to your masterpiece, you star, you.
      </p>
      ${renderedDownloadButton}
    </span>
  `

  const renderedMessage =
    downloadSlug === 'processing'
      ? renderedProcessingMessage
      : renderedDownloadMessage

  let shippingMessage
  switch (state.purchaseResult.shippingStatus) {
    case 'awaiting_fulfillment':
      shippingMessage = html`
        <span>
          <p class="item-description">
            You'll receive an email from us when your item is printed and
            shipped about how to handle your soon-to-be oppressively jealous
            admirers.
          </p>
        </span>
      `
      break
    case 'not_required':
    default:
      shippingMessage = null
  }

  // prettier-ignore
  return html`
    <main class="merch-detail-page merch-success-page">
      ${ header({ pageTitle: pageTitle, withCamera: true, withGallery: true}) }
      <div class="merch-item">
        <img class="item-image" src="${state.purchaseResult.mockups[0]}" />
        <p class="item-description">Whoa, look at you! How good does that feel? Nobody else has this. Only you have this.</p>
        ${ shippingMessage }
        ${ renderedMessage }
        <div class="line"></div>
        ${ CameraButton.render({ href: '/camera' })}
        ${ GalleryButton.render({ href: '/gallery' })}
      </div>
    </main>
  `
}

export default success
