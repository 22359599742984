import Button from './button'

class CircleButton extends Button {
  createElement(props) {
    const el = super.createElement(props)
    el.classList.add('circle-button')
    return el
  }
}

export default CircleButton
