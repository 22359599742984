import cuid from 'cuid'

const USER_ID_LOCAL_STORAGE_KEY = 'ec_u'
const SNAPSHOTS_LOCAL_STORAGE_KEY = 'ec_img'
const SNAPSHOTS_COUNTER_LOCAL_STORAGE_KEY = 'ec_c'

export const getUserId = () => {
  // User Id, so we can get people their stuff if something goes wrong
  // do we have a userId?
  let storedUserId = localStorage.getItem(USER_ID_LOCAL_STORAGE_KEY)
  if (!storedUserId) {
    storedUserId = cuid()
    localStorage.setItem(USER_ID_LOCAL_STORAGE_KEY, storedUserId)
  }

  return storedUserId
}

export const advanceCounter = () => {
  let currentCounter = localStorage.getItem(SNAPSHOTS_COUNTER_LOCAL_STORAGE_KEY)
  if (!currentCounter) {
    currentCounter = '0'
  }
  currentCounter = parseInt(currentCounter)
  currentCounter++
  localStorage.setItem(SNAPSHOTS_COUNTER_LOCAL_STORAGE_KEY, currentCounter)
  return currentCounter
}

export const saveSnapshot = (slug, snapshotId) => {
  let snapshots = localStorage.getItem(SNAPSHOTS_LOCAL_STORAGE_KEY)

  if (snapshots === null) {
    snapshots = '{}'
  }

  snapshots = JSON.parse(snapshots)

  snapshots[slug] = snapshotId

  localStorage.setItem(SNAPSHOTS_LOCAL_STORAGE_KEY, JSON.stringify(snapshots))
}

export const getSnapshotFromSlug = slug => {
  const snapshots = getAllSnapshots()
  return snapshots[slug]
}

export const getAllSnapshots = () => {
  let snapshots = localStorage.getItem(SNAPSHOTS_LOCAL_STORAGE_KEY)

  if (snapshots === null) {
    snapshots = '{}'
  }

  return JSON.parse(snapshots)
}

export const removeSnapshotBySnapshotId = snapshotId => {
  const snapshots = getAllSnapshots()
  for (let slug in snapshots) {
    if (snapshots[slug] === snapshotId) {
      delete snapshots[slug]
    }
  }
  localStorage.setItem(SNAPSHOTS_LOCAL_STORAGE_KEY, JSON.stringify(snapshots))
}
