// grossssss
const getSnapshotIdFromImageId = (state, imageId) => {
  const imageIdx = parseInt(imageId) - 1

  if (state.isImageCacheEmpty) {
    return null
  }

  if (state.images.size > 3) {
    debugger
  }

  if (imageIdx >= state.images.size) {
    return null
  }

  const snapshotId = Array.from(state.images.keys())[imageIdx].split('.')[0]
  return snapshotId
}

export default getSnapshotIdFromImageId
