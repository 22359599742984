import html from 'choo/html'
import platform from 'platform'
import header from '../components/header'
import IconButton from '../components/icon-button'
import CopyButton from '../components/copy-button'

const CameraButton = new CopyButton('camera-flash', 'Camera')

function gallery(state, emit) {
  const images = []

  const snapshotSlugs = Object.keys(state.snapshots)
    .map(slug => parseInt(slug))
    .sort()
    .reverse()

  if (state.products === null) {
    emit('get-products')
  }

  for (let snapshotSlug of snapshotSlugs) {
    const snapshotId = state.snapshots[snapshotSlug]
    const snapshotBlob = state.snapshotBlobs[snapshotId]
    if (!snapshotBlob) {
      continue
    }
    const imageObjectURL = URL.createObjectURL(snapshotBlob)
    const img = html`
      <img class="preview" src="${imageObjectURL}" />
    `
    img.onload = () => img.classList.add('is-loaded')
    if (img.complete) img.classList.add('is-loaded')

    const imageWrapper = html`
      <a
        href="/gallery/${snapshotSlug}"
        class="image-wrapper"
        id="image-wrapper-${snapshotSlug}"
      >
        ${img}
      </a>
    `

    images.push(imageWrapper)
  }

  let renderedContent

  const userHasNoSnapshots = Object.keys(state.snapshots).length === 0

  if (userHasNoSnapshots) {
    renderedContent = html`
      <div class="image-grid is-empty">
        <div class="empty-message">
          <p>You haven't taken any images yet.<br />Let's fix that!</p>
          ${CameraButton.render({ href: '/camera' })}
        </div>
      </div>
    `
  } else {
    renderedContent = html`
      <div class="image-grid">
        ${images}
      </div>
    `
  }

  // prettier-ignore
  return html`
    <main class="gallery-page">
      ${ header({ pageTitle: 'Gallery', withCamera: true, withGallery: false}) }
      ${ renderedContent }
    </main>
  `
}

export default gallery
