import html from 'choo/html'
import header from '../components/header'
import getProductFromSlug from '../helpers/get-product-from-slug'
import CopyButton from '../components/copy-button'

const PurchaseButton = new CopyButton('lightning', 'Purchase')

const onPurchaseClick = (emit, product, snapshotId) => {
  if (product.skus.length === 1) {
    const skuId = product.skus[0].id

    emit('product-purchase', {
      snapshotId,
      productId: product.id,
      skuId
    })
  } else {
    throw new Error('multi sku products not yet supported')
  }
}

function merchDetail(state, emit) {
  const snapshotSlug = state.params.snapshotSlug
  const snapshotId = state.snapshots[snapshotSlug]

  if (state.products === null) {
    emit('get-products')
  }

  // redirect to gallery if no images
  const userHasNoImages = Object.keys(state.snapshots).length === 0
  if (userHasNoImages) {
    emit('replaceState', '/gallery')
  }

  const isNotValidSlug = typeof snapshotId === 'undefined'
  if (isNotValidSlug) {
    emit('replaceState', '/gallery')
  }

  const product = getProductFromSlug(state.products, state.params.merchItemSlug)
  const productId = product ? product.id : null

  if (product === null) {
    return html`
      <main></main>
    `
  }

  const canHaveMockup = product.metadata.printful_product_id

  // if no mockups exist
  let mockups = null
  console.log(state.mockups)
  if (state.mockups[snapshotId] === undefined && canHaveMockup) {
    const skuId = product.skus[0].id
    emit('request-mockup', {
      snapshotId,
      productId,
      skuId
    })
  } else if (typeof state.mockups[snapshotId] === 'object') {
    mockups = state.mockups[snapshotId][product.id]
  }

  const boundOnPurchaseClick = onPurchaseClick.bind(
    null,
    emit,
    product,
    snapshotId
  )

  const pageTitle = product.metadata.store_name

  let generatingPreviewMessage = canHaveMockup
    ? html`
        <p>Generating your preview...</p>
      `
    : null

  let merchImage = html`
    <img class="item-image" src="${product.metadata.store_image}" />
  `

  if (mockups !== null && typeof mockups === 'object') {
    window.mockups = mockups
    const firstMockup = mockups[Object.keys(mockups)[0]][0]
    merchImage = html`
      <img class="item-image" src="${firstMockup}" />
    `
    generatingPreviewMessage = null
  }

  let itemPrice
  if (product.minPrice === product.maxPrice) {
    itemPrice = `${product.minPrice / 100} USD`
  } else {
    itemPrice = `${product.minPrice / 100}+ USD`
  }

  // prettier-ignore
  return html`
    <main class="merch-detail-page">
    ${ header({ pageTitle: pageTitle, withCamera: true, withGallery: true}) }
      <div class="merch-item">
        ${ merchImage }
        ${ generatingPreviewMessage }
        <div class="merch-item-info">
          <div class="merch-item-price">${itemPrice}</div>
          <div class="merch-item-options">${product.metadata.store_options}</div>
        </div>
        
        <p class="item-description">${product.metadata.store_description}</p>
        ${ state.isAwaitingPurchase ? html`<p>Loading...</p>` : PurchaseButton.render({ onClick: boundOnPurchaseClick  }) }
      </div>
    </main>
  `
}

export default merchDetail
