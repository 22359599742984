import knot from 'knot.js'
import BezierEasing from 'bezier-easing'
import EmojiCalc from './emoji-calc/Cargo.toml'

const EmojiView = knot({
  pages: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  flashBezier: BezierEasing(0.74, 0.04, 0.9, 0.35),
  flashDuration: 2000,
  async init({ canvas, video, settings = {} }) {
    this.video = video
    this.onscreenCanvas = document.getElementById('canvas')
    this.bufferCanvas = document.createElement('canvas')
    this.settings = Object.assign(
      {
        size: 40,
        density: 0,
        isHorizontallyFlipped: true
      },
      settings
    )

    this.resize()

    this.bufferContext = this.bufferCanvas.getContext('2d')
    this.onscreenContext = this.onscreenCanvas.getContext('2d')
    this.bufferContext.fillStyle = '#393939'
    this.draw = this.draw.bind(this)
    this.drawEmpty()

    await this.loadSpritePages()
  },
  onSpriteResponse(pageIdx, spriteImageBlob) {
    // when postMessaged, it loses its type
    const spriteImageBlobClone = spriteImageBlob.slice(
      0,
      spriteImageBlob.size,
      'image/png'
    )
    const imageObjectURL = URL.createObjectURL(spriteImageBlobClone)
    this.pages[pageIdx].src = imageObjectURL
  },
  async loadSpritePages() {
    if (this.hasBegunLoadingSpritePages) return Promise.resolve()
    this.hasBegunLoadingSpritePages = true
    const imageLoadPromises = this.pages.map(async idx => {
      if (idx instanceof HTMLImageElement) return Promise.resolve(idx)
      this.pages[idx] = await this.loadSpritePage(idx)
      return this.pages[idx]
    })
    return await Promise.all(imageLoadPromises)
  },
  resize() {
    this.canvasWidth = this.onscreenCanvas.offsetWidth * 2 + 40
    this.canvasHeight = this.onscreenCanvas.offsetHeight * 2 + 40

    this.onscreenCanvas.width = this.canvasWidth
    this.onscreenCanvas.height = this.canvasHeight
    this.bufferCanvas.width = this.canvasWidth
    this.bufferCanvas.height = this.canvasHeight

    this.recalculateDimensions()
  },
  recalculateDimensions() {
    // how many emojis can we fit across
    // this is the width and height of emojis we will see on the screen
    this.captureWidth =
      Math.floor(
        this.canvasWidth /
          (this.settings.size + this.settings.density * this.settings.size)
      ) - 1
    this.captureHeight = Math.ceil(
      (this.captureWidth * this.canvasHeight) / this.canvasWidth
    )

    // the source video dimensions
    this.videoHeight = this.video.videoHeight
    this.videoWidth = this.video.videoWidth

    // the source video's aspect ratio will be different than our capture aspect ratio
    // this is the dimensions of the rendered video, some of which will be rendered outside
    // of the canvas due to downsizedVideoOffset which should always be negative
    this.downSizedVideoHeight = this.captureHeight
    this.downSizedVideoWidth =
      (this.videoWidth * this.downSizedVideoHeight) / this.videoHeight
    this.downSizedVideoOffset =
      ((this.downSizedVideoWidth - this.captureWidth) / 2.0) * -1

    this.isVideoReady = this.videoHeight * this.videoWidth > 0

    // the emoji grid will be smaller than the canvas
    // this is the pixel dimensions of the grid inside of the canvas
    this.emojiGridRenderedWidthPixels =
      this.captureWidth * this.settings.size +
      this.captureWidth * this.settings.size * this.settings.density
    this.emojiGridRenderedHeightPixels =
      this.captureHeight * this.settings.size +
      this.captureHeight * this.settings.size * this.settings.density

    // these are the pixel offsets added to each emoji's rendered X,Y coords
    // so that the resulting grid will be centered in the canvas
    this.xOffset = Math.floor(
      (this.canvasWidth - this.emojiGridRenderedWidthPixels) / 2
    )
    this.yOffset = Math.floor(
      (this.canvasHeight - this.emojiGridRenderedHeightPixels) / 2
    )

    // prettier-ignore
    console.log(`
      size: ${this.settings.size}
      density: ${this.settings.density}
      capture: ${this.captureWidth}w ${this.captureHeight}h
      canvas: ${this.canvasWidth}w ${this.canvasHeight}h
      emojiGrid: ${this.emojiGridRenderedWidthPixels}w ${this.emojiGridRenderedHeightPixels}h
      offsets: ${this.xOffset}w ${this.yOffset}h
    `)
  },

  loadSpritePage(pageIdx) {
    // console.log('load sprite page', pageIdx)
    return new Promise((resolve, reject) => {
      this.emit('begin-sprite-image-load')
      const img = new Image()
      this.pages[pageIdx] = img
      const onLoad = () => {
        this.emit('end-sprite-image-load')
        resolve(img)
      }
      const onError = () => {
        this.emit('end-sprite-image-load')
        reject(img)
      }
      img.addEventListener('load', onLoad, { once: true })
      img.addEventListener('error', onError, { once: true })
      this.emit('sprite-requested', pageIdx)
    })
  },
  start() {
    performance.mark('canvas start')
    this.isRunning = true
    window.requestAnimationFrame(this.draw)
  },
  drawEmpty() {
    this.onscreenContext.fillStyle = '#393939'
    this.onscreenContext.fillRect(0, 0, this.canvasWidth, this.canvasHeight)
  },
  draw(timestamp) {
    let frameDuration
    if (this.previousTimestamp) {
      frameDuration = timestamp - this.previousTimestamp
    }
    this.previousTimestamp = timestamp

    if (!this.isVideoReady) {
      this.resize()
      window.requestAnimationFrame(this.draw)
      return
    }
    if (!this.isRunning) {
      this.drawEmpty()
      return
    }
    this.bufferContext.clearRect(
      0,
      0,
      this.downSizedVideoWidth,
      this.downSizedVideoHeight
    )
    this.bufferContext.drawImage(
      this.video,
      this.downSizedVideoOffset,
      0,
      this.downSizedVideoWidth,
      this.downSizedVideoHeight
    )

    const colorValues = this.bufferContext.getImageData(
      0,
      0,
      this.captureWidth,
      this.captureHeight
    ).data

    const emojiPositions = EmojiCalc.calc_frame(colorValues)

    this.bufferContext.fillStyle = '#393939'
    this.bufferContext.fillRect(0, 0, this.canvasWidth, this.canvasHeight)

    // TODO some of this math might be calculated faster in Rust or gpu.js
    emojiPositions.forEach((emojiPosition, capturedPixelIndex) => {
      // the x position of the capture video that this pixel represents (capturedPixelIndex)
      const capturedPixelX = capturedPixelIndex % this.captureWidth

      // the y position in the captrue video that this pixel
      const capturedPixelY =
        (capturedPixelIndex - capturedPixelX) / this.captureWidth

      // the x position where we should draw the emoji
      let canvasPositionX =
        // x position, if density were 0
        capturedPixelX * this.settings.size +
        // added to the sum of the sapce we've accumulated so far on this row
        capturedPixelX * this.settings.density * this.settings.size +
        // we offset the grid so emojis arent cut off
        this.xOffset

      // the y position where we should draw the emoji
      let canvasPositionY =
        capturedPixelY * this.settings.size +
        capturedPixelY * this.settings.density * this.settings.size +
        this.yOffset
      if (this.settings.isHorizontallyFlipped) {
        const capturedPixelXFlipped = this.captureWidth - capturedPixelX - 1
        canvasPositionX =
          capturedPixelXFlipped * this.settings.size +
          capturedPixelXFlipped * this.settings.density * this.settings.size +
          this.xOffset
      }

      const [p, x, y] = emojiPosition
      if (this.pages) {
        this.bufferContext.drawImage(
          this.pages[p],
          x,
          y,
          100,
          100,
          canvasPositionX,
          canvasPositionY,
          this.settings.size,
          this.settings.size
        )
      }
    })

    this.onscreenContext.drawImage(this.bufferCanvas, 0, 0)
    if (this.flashStart) {
      const flashTime =
        1 -
        Math.min(
          Math.max((timestamp - this.flashStart) / this.flashDuration, 0),
          1
        )
      const flashOpacity = this.flashBezier(flashTime)
      this.onscreenContext.fillStyle = `rgba(255, 255, 255, ${flashOpacity})`
      this.onscreenContext.fillRect(0, 0, this.canvasWidth, this.canvasHeight)
      if (flashTime <= 0) this.flashStart = null
    }

    if (this.isSnapshotting) {
      this.isSnapshotting = false

      const emojiNames = EmojiCalc.calc_frame_names(colorValues)

      const snapshotData = {
        emojiRows: 0,
        emojiColumns: 0,
        size: this.settings.size,
        density: this.settings.density,
        windowW: window.outerWidth,
        windowH: window.outerHeight,
        canvasW: this.canvasWidth,
        canvasH: this.canvasHeight,
        downsizedVideoW: this.downSizedVideoWidth,
        downsizedVideoH: this.downSizedVideoHeight,
        emojiColumns: this.captureWidth,
        emojiRows: this.captureHeight,
        pixelDensity: window.devicePixelRatio,
        emojiArray: emojiNames,
        dataUrl: this.bufferCanvas.toDataURL('image/png')
      }

      this.emit('snapshot', snapshotData)
    }

    // window.requestAnimationFrame(this.draw)
    if (frameDuration < 60) {
      window.requestAnimationFrame(this.draw)
    } else {
      // if we're running slow
      // give the browser time to catch up before the next frame
      // try again in 3/4 the time minus the time a normal 30FPS would take
      const delay = frameDuration * 0.65 - 30
      setTimeout(() => window.requestAnimationFrame(this.draw), delay)
    }
    // setTimeout(() => this.draw(), 1000)
  },
  stop() {
    this.isRunning = false
  },
  snapshot() {
    this.flashStart = performance.now()
    this.onscreenContext.fillStyle = `rgba(255, 255, 255, 1)`
    this.onscreenContext.fillRect(0, 0, this.canvasWidth, this.canvasHeight)
    this.isSnapshotting = true
  }
})

export default EmojiView
