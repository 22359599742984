import camera from './lib/camera'
import emojiCanvas from './lib/emoji-canvas'
import {
  getUserId,
  saveSnapshot,
  getAllSnapshots,
  advanceCounter,
  removeSnapshotBySnapshotId
} from './lib/storage'
import api from './lib/api'
import stripe from './lib/stripe'
import cuid from 'cuid'

window.initialState = {
  worker: null,
  queuedWorkerTasks: [],
  snapshots: getAllSnapshots(),
  snapshotBlobs: {},
  snapshotDownloadSlugs: {},
  isAwaitingPurchase: false,
  mockups: {},
  emojis: {
    isReady: false,
    numQueued: 0,
    numReady: 0
  },
  cameraSettings: {
    isPlaying: false,
    currentFacingMode: 0,
    availableFacingModes: []
  },
  canvasSettings: {
    size: {
      min: 30,
      max: 100,
      value: 47.5
    },
    density: {
      min: -0.5,
      max: 0.5,
      value: -0.25
    }
  },
  controls: {
    density: {
      value: 0.25,
      isActive: false
    },
    size: {
      value: 0.25,
      isActive: false
    }
  },
  loadingTasksTotal: 11,
  loadingTasksCompleted: 0,
  products: null
}

window.emojiImages = {} // must be defined before WASM is initted
window.emojisReady = false

function store(state, emitter) {
  emitter.on('control-activate', controlName => {
    // console.log(controlName, 'activate')
    state.controls[controlName].isActive = true
    emitter.emit('render')
  })

  emitter.on('control-deactivate', controlName => {
    // console.log(controlName, 'deactivate')
    state.controls[controlName].isActive = false
    emitter.emit('render')

    const normalizedValue = parseInt(state.controls[controlName].value * 100)
    ga('send', {
      hitType: 'event',
      eventCategory: 'Camera',
      eventAction: `control-changed`,
      eventLabel: controlName,
      eventValue: normalizedValue
    })
  })

  emitter.on('control-change', (controlName, value) => {
    state.controls[controlName].value = value

    const min = state.canvasSettings[controlName].min
    const max = state.canvasSettings[controlName].max
    const newCanvasSettingValue = parseFloat(
      ((max - min) * value + min).toFixed(4)
    )
    state.canvasSettings[controlName].value = newCanvasSettingValue
    emojiCanvas.settings[controlName] = state.canvasSettings[controlName].value
    emojiCanvas.recalculateDimensions()
    emitter.emit('render')
  })

  emitter.on('snapshot-taken', async snapshotData => {
    snapshotData.userId = state.userId
    snapshotData.cameraW = camera.width
    snapshotData.cameraH = camera.height
    snapshotData.hostname = window.location.hostname
    snapshotData.snapshotId = cuid()
    snapshotData.slug = advanceCounter()

    saveSnapshot(snapshotData.slug, snapshotData.snapshotId)
    state.snapshots = getAllSnapshots()

    state.worker.postMessage({
      type: 'snapshot',
      snapshotId: snapshotData.snapshotId,
      data: snapshotData
    })
  })

  emitter.on('take-snapshot', async canvas => {
    // might look into using the async toBlob here
    // but it seemed to introduce a lag
    const snapshotDataUrl = emojiCanvas.snapshot()
    ga('send', {
      hitType: 'event',
      eventCategory: 'Camera',
      eventAction: 'snapshot'
    })
  })

  emitter.on('worker-initted', worker => {
    state.worker = worker
    state.worker.onmessage = msg => emitter.emit('worker-message', msg)
  })

  emitter.on('worker-message', msg => {
    switch (msg.data.type) {
      case 'image-added':
        emitter.emit('image-added', msg.data.snapshotId, msg.data.imageBlob)
        break
      case 'sprite-response':
        emojiCanvas.onSpriteResponse(
          msg.data.spriteIndex,
          msg.data.spriteImageBlob
        )
      case 'worker-ready':
        emitter.emit('worker-ready')
        break
      default:
        break
    }
  })

  emitter.on('worker-ready', () => {
    state.worker.postMessage({
      type: 'load-cached-snapshots',
      data: state.snapshots
    })

    if (state.queuedWorkerTasks) {
      state.queuedWorkerTasks.forEach(task => task(state.worker))
      state.queuedWorkerTasks = []
    }

    state.userId = getUserId()
  })

  emitter.on('sprite-requested', spriteIndex => {
    console.log('sprite requested')
    const fireRequestSprite = worker => {
      state.worker.postMessage({
        type: 'request-sprite',
        spriteIndex
      })
    }
    if (state.worker) {
      fireRequestSprite(state.worker)
    } else {
      state.queuedWorkerTasks.push(fireRequestSprite)
    }
  })

  emitter.on('image-added', async (snapshotId, imageBlob) => {
    // have to copy the image to make sure we get the right mime type
    const blobClone = imageBlob.slice(0, imageBlob.size, 'image/png')
    if (state.snapshots) {
      state.snapshotBlobs[snapshotId] = blobClone
    }
    emitter.emit('render')
  })

  emitter.on('loading-task-completed', () => {
    state.loadingTasksCompleted++
    // console.log(state.loadingTasksCompleted)
    emitter.emit('render')
  })

  emitter.on('start-camera', async () => {
    emojiCanvas.init({
      video: camera.video,
      settings: {
        density: state.canvasSettings.density.value,
        size: state.canvasSettings.size.value
      }
    })
    // emitter.emit('loading-task-completed')

    try {
      const capabilities = await camera.start()
      emojiCanvas.start()
      // emitter.emit('loading-task-completed')
      state.cameraSettings.availableFacingModes =
        capabilities.availableFacingModes || []
      state.cameraSettings.isPlaying = true
      emitter.emit('render')
    } catch (e) {
      if (e.message === 'NO_CAMERA') {
        state.doesLackCameraCapability = true
        emitter.emit('render')
        ga('send', 'exception', {
          exDescription: 'No camera'
        })
      } else {
        ga('send', 'exception', {
          exDescription: e.message
        })
        console.error(e)
        console.warn('Unable to init camera video feed.')
      }
    }
    emitter.emit('loading-task-completed')
  })

  emitter.on('stop-camera', async () => {
    await camera.stop()
    state.cameraSettings.isPlaying = false
    await emojiCanvas.stop()
    emitter.emit('render')
  })

  emitter.on('toggle-camera-direction', async () => {
    console.log('toggle camera direction')

    if (state.cameraSettings.availableFacingModes.length <= 1) {
    } else {
      const nextFacingMode =
        state.cameraSettings.availableFacingModes[
          (state.cameraSettings.currentFacingMode += 1) %
            state.cameraSettings.availableFacingModes.length
        ]
      console.log(nextFacingMode)
      await camera.toggleFacingMode(nextFacingMode)
      emojiCanvas.settings.isHorizontallyFlipped =
        nextFacingMode !== 'environment'

      ga('send', {
        hitType: 'event',
        eventCategory: 'Camera',
        eventAction: 'switch-camera-direction',
        eventLabel: nextFacingMode
      })
    }
  })

  emitter.on('delete', snapshotId => {
    removeSnapshotBySnapshotId(snapshotId)
    state.snapshots = getAllSnapshots()
    delete state.snapshotBlobs[snapshotId]
    emitter.emit('render')
    state.worker.postMessage({
      type: 'delete',
      snapshotId
    })

    ga('send', {
      hitType: 'event',
      eventCategory: 'Gallery',
      eventAction: 'delete'
    })
  })

  emitter.on('navigate', async () => {
    state.isAwaitingPurchase = false
    ga('send', 'pageview', window.location.pathname)
  })

  emitter.on('resize', () => {
    if (state.cameraSettings.isPlaying === true) {
      emojiCanvas.resize()
    }
  })

  emitter.on('product-purchase', async params => {
    state.isAwaitingPurchase = true
    emitter.emit('render')
    ga('send', {
      hitType: 'event',
      eventCategory: 'Merch',
      eventAction: 'purchase-clicked',
      eventLabel: params.sku,
      transport: 'beacon'
    })

    const { productId, snapshotId, skuId, shipping, details = {} } = params
    const product = state.products.find(product => product.id === productId)

    if (product.shippable && !params.shipping) {
      emitter.emit('pushState', window.location.pathname + '/shipping')
      state.isAwaitingPurchase = false
      return
    }

    if (!product.shippable) {
      details.quantity = 1
    }

    const { sessionId, purchase, purchaseId } = await api.createPurchase({
      userId: getUserId(),
      snapshotId: snapshotId,
      productId: params.productId,
      skuId: skuId,
      quantity: details.quantity,
      shippingInfo: shipping,
      cancelUrl: window.location.href,
      successUrl: `https://${window.location.host}/gallery/${
        state.params.snapshotSlug
      }/merch/${state.params.merchItemSlug}/success` // the server will add productId, skuId, purchaseId
    })

    const checkoutResult = await stripe.redirectToCheckout({
      sessionId
    })
    ga('send', {
      hitType: 'event',
      eventCategory: 'Purchase',
      eventAction: 'checkout-started'
    })
  })

  emitter.on('request-download-slug', async snapshotId => {
    state.snapshotDownloadSlugs[snapshotId] = 'requested'
    const response = await api.requestDownloadSlug(snapshotId, getUserId())
    if (response === null) {
      state.snapshotDownloadSlugs[snapshotId] = null
    } else if (response.status === 'processing') {
      state.snapshotDownloadSlugs[snapshotId] = 'processing'
      setTimeout(() => emitter.emit('request-download-slug', snapshotId), 5000)
    } else {
      state.snapshotDownloadSlugs[snapshotId] = response.downloadSlug
    }
    emitter.emit('render')
  })

  emitter.on('request-mockup', async ({ snapshotId, productId, skuId }) => {
    const userId = getUserId()

    if (state.mockups[snapshotId] === undefined) {
      state.mockups[snapshotId] = {}
    }
    if (state.mockups[snapshotId][productId] === undefined) {
      state.mockups[snapshotId][productId] = {}
    }
    if (state.mockups[snapshotId][productId][skuId] === undefined) {
      state.mockups[snapshotId][productId][skuId] = 'pending'
    }

    const response = await api.getMockup({
      snapshotId,
      productId,
      userId,
      skuId
    })

    if (response.status === 'pending') {
      state.mockups[snapshotId][productId][skuId] = 'pending'
      setTimeout(() => {
        emitter.emit('request-mockup', { snapshotId, productId, skuId })
      }, 5000)
      emitter.emit('render')
    } else if (response.status === 'complete') {
      console.log('store', response.mockups)
      state.mockups[snapshotId][productId][skuId] = response.mockups
      emitter.emit('render')
    } else {
      console.warn("Requested a snapshot when we shouldn'tve")
    }
  })

  emitter.on('get-products', async () => {
    const productsPromise = api.getProducts()
    state.products = []
    const { products } = await productsPromise

    products.forEach((product, idx) => {
      product.activeSku = product.metadata.default_sku
      product.skus.forEach(sku => {
        if (product.minPrice === undefined) {
          product.minPrice = sku.price
        } else if (product.minPrice > sku.price) {
          product.minPrice = sku.price
        }
        if (product.maxPrice === undefined) {
          product.maxPrice = sku.price
        } else if (product.maxPrice < sku.price) {
          product.maxPrice = sku.price
        }
      })
    })

    state.products = products
    emitter.emit('render')
  })

  emitter.on('get-purchase', async ({ purchaseId, productId, skuId }) => {
    state.purchaseResult = 'pending'
    const userId = getUserId()
    const purchase = await api.getPurchase({
      purchaseId,
      productId,
      skuId,
      userId
    })
    state.purchaseResult = purchase
    emitter.emit('render')
  })

  emojiCanvas.on('end-sprite-image-load', () => {
    emitter.emit('loading-task-completed')
  })
  emojiCanvas.on('sprite-requested', spriteIndex => {
    emitter.emit('sprite-requested', spriteIndex)
  })
  emojiCanvas.on('snapshot', snapshotData => {
    emitter.emit('snapshot-taken', snapshotData)
  })
}

export default store
