import html from 'choo/html'
import CopyButton from '../components/copy-button'

const CameraButton = new CopyButton('camera-flash', 'Camera')
const CameraButton2 = new CopyButton('camera-flash', 'Camera')
const GalleryButton = new CopyButton('gallery', 'Gallery')

function homepage(state, emit) {
  // prettier-ignore
  const el = html`
    <main class="homepage">
      <header>
        <img class="section-emoji" src="/shapes/star-eyes.svg" />
        <h1>Emoji Camera</h1>
        <p>See the world, in emojis.</p>
        ${ CameraButton.render({ href: "/camera", classes: "white"}) }
      </header>
      <img class="yellow-blob" src="/shapes/yellow-blob.svg">
      <section class="section-2">
        <div class="props">
          <div class="prop">
            <img class="section-emoji" src="/shapes/tent.svg"  />
            <p>
            Tell everyone.<br>
              Endless family fun.<br>
              Emojis are everywhere.
            </p>
          </div>
          <div class="prop">
            <img class="section-emoji" src="/shapes/star-swoop.svg"  />
            <p>
            Use your camera to make<br />
            a magnificent materpiece<br /> 
            out of emoji.
            </p>
          </div>
          <div class="prop">
            <img class="section-emoji" src="/shapes/dancing-lady.svg"  />
            <p>
              Download your creation for free<br>
              or nab some merch.
            </p>
          </div>
        </div>
      </section>
      <img src="/shapes/green-blob.svg" class="green-blob" />
      <section class="section-7">
        <div class="copy">
          <p>Let's kick it.</p>
        </div>
        ${ CameraButton2.render({ href:"/camera" })}
        ${ GalleryButton.render({ href:"/gallery" })}
      </section>
    </main>
  `

  function onCameraClick(e) {
    e.preventDefault()
    emit('navigate', 'camera')
  }

  return el
}

export default homepage
